import React, { Component } from "react";
import Form from "./Form";
// import Background from '../images/background.svg';
// import BackgroundSmall from '../images/BackgroundSmall2.svg';
// import Illustrate from '../images/ChangedIllustration.svg';
// import SVGCorona from "../images/SVGCorona.svg";
// import NewLogo from "../images/newLogo.png";
//import BackgroundBhopal from "../images/Bhopal.jpg";
// import MobileBanner from "../images/IndependenceDayBannerMob.png";
// import BackgroundImageNew from "../images/IndependenceDayBannerWeb.png";

// import MobileBanner from "../images/MobileBannerInde.gif";
// import BackgroundImageNew from "../images/WebBannerInde.gif";
// import classplusLogo from "../images/favicon.ico"
// import MobileBanner from "../images/MobileBanner1.jpg";
// import MobileBanner from "../images/Mobile-Banner.jpg";
// import BackgroundImageNew from "../images/Desktop-Banner.jpg";
// import MobileBanner from "../images/MobileBannerJan.png";
import BackgroundImageNew from "../images/DesktopBannerJan.jpg";
// import BackgroundImageNew from "../images/DesktopBanner.jpg";
// import whatsappIcon from "../images/whatsapp.svg";
// import { ScrollTo } from "react-scroll-to";
import { buildType } from "../utils/constant";
import whatsappCTA from "../images/whatsappCTA.svg"
class Landing extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (
      m,
      key,
      value
    ) {
      vars[key] = value;
    });

    this.setState({
      utm_source: vars["utm_source"],
      utm_medium: vars["utm_medium"],
      utm_campaign: vars["utm_campaign"],
    });

    // fetch('https://skynet.classplusapp.com/api/v1/redirect/lead-image/book-a-demo-landing-image',{
    //     method:'GET',?utm_source=google&utm_medium=email&utm_campaign=kuchBhi

    // }).then(
    //     this.setState({
    //         imageUrl:
    //     })
    // )
  }

  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions());
  }
  render() {
    const background = () => {
      if (window.innerWidth < 600) {
        return {
          backgroundImage: `url(${'https://d21qmqg3g5bkb7.cloudfront.net/production/single/learn/0491aba8-9724-4a5f-ad0a-1fdffa5d80f6.gif'})`,
          width: "100%",
          backgroundSize: "contain",
          height: "34vh",
          backgroundPosition: "left",

        };
      } else {
        return {
          backgroundImage: `url(${'https://d21qmqg3g5bkb7.cloudfront.net/production/single/learn/23c24cd6-9cdf-493e-921f-cefa1b07eb76.gif'})`,
          backgroundPosition: "left",
          // width: "100%",
          backgroundSize: "cover",
          // height: '100%'
        };
      }
    };

    const { utm_source, utm_medium, utm_campaign } = this.state;
    let param1, param2, param3;
    param1 =
      utm_source && utm_source.length > 0 ? `&utm_source=${utm_source}` : "";
    param2 =
      utm_medium && utm_medium.length > 0 ? `&utm_medium=${utm_medium}` : "";
    param3 =
      utm_campaign && utm_campaign.length > 0
        ? `&utm_campaign=${utm_campaign}`
        : "";

    //&source=&data=&app_absent=
    let whatsAppUrl = `https://api.whatsapp.com/send?phone=918588809864&text=Hi%20Classplus${param1}${param2}${param3}`;
    // console.log(whatsAppUrl, 'whatsAppUrl');

    return (
      <div
        className="landing grid--1"
        style={
          window.innerWidth > 600
            ? background()
            : { width: window.innerWidth > 600 ? "85%" : "100%" }
        }
      >
        <div
          className="landing__content"
          style={
            window.innerWidth < 600
              ? background()
              : {
                width: "65%",
                marginTop: window.innerWidth > 768 ? "5rem" : "0",
              }
          }
        >
          {/* <div className="landing__content--Item">
            <div className="landing__content--Item-Text">
              <div className="landing__content--Item-Text-heading">20000+ Teachers</div>
              <div className="landing__content--Item-Text-normalText">grew their Coaching Online during</div>
              <div className="landing__content--Item-Text-normalText">Covid with <span style={{ fontWeight: "bold" }}>Classplus</span></div>
            </div>
            <div className="TextContentGroup">
              <div className="landing__content--Item-TextContent">
                <img src={classplusLogo} alt="" className="pointImg" />
                <div className="landing__content--Item-TextContent-point">Conducting Online classes</div>
              </div>

              <div className="landing__content--Item-TextContent">
                <img src={classplusLogo} alt="" className="pointImg" />
                <div className="landing__content--Item-TextContent-point">Taking video lectures</div>
              </div>

              <div className="landing__content--Item-TextContent">
                <img src={classplusLogo} alt="" className="pointImg" />
                <div className="landing__content--Item-TextContent-point">Evaluating students with online tests</div>
              </div>

              <div className="landing__content--Item-TextContent">
                <img src={classplusLogo} alt="" className="pointImg" />
                <div className="landing__content--Item-TextContent-point">Selling courses and study material online</div>
              </div>
            </div>
          </div> */}

          {
            //  <div className="landing__content--main">
            //   <div>
            //     Launch <span style={{ color: "#fbd449" }}>your</span> online{" "}
            //   </div>
            //   <div>
            //     <span style={{ color: "#fbd449" }}>teaching platform</span>{" "}
            //     easily.
            //   </div>
            // </div>
            // <div className="landing__content--sub">
            //   Take a step towards building a self-reliant India by taking your
            //   coaching online!
            // </div>
          }
          {
            // <div
            //   className="landing__content--sub landing__content--sub--hashtag"
            //   style={{ marginTop: "1rem" }}
            // >
            //   #FightAgainstCorona
            // </div>
            // <div className="landing__content--sub  landing__content--sub--prompt ">
            //   Go Digital Now!
            // </div>
          }
          {
            // <div
            //   className={window.innerWidth < 600 ? "centerAlignmentNew" : ""}
            // >
            //   {window.innerWidth > 600 ? (
            //     <div className="whatsappBtn btn btn--bookADemo form__button instantFix2">
            //       <a
            //         href={whatsAppUrl}
            //         target="_blank"
            //         className="whatsappAnchor"
            //       >
            //         <img
            //           alt=""
            //           src={whatsappIcon}
            //           style={{ height: "20px", width: "20px" }}
            //         />
            //         <p style={{ paddingLeft: "8px" }}>
            //           {buildType == "tutor"
            //             ? "Book Your App Now"
            //             : "Get Your Free App"}
            //         </p>
            //       </a>
            //     </div>
            //   ) : (
            //     <ScrollTo>
            //       {({ scrollTo }) => (
            //         <button
            //           className="whatsappBtn whatsappAnchor form__button btn btn--bookADemo instantFix"
            //           onClick={() => {
            //             window.innerWidth > 600
            //               ? window.innerWidth > 1400
            //                 ? window.innerWidth > 1600
            //                   ? scrollTo({ y: 4600, smooth: true })
            //                   : scrollTo({ y: 3900, smooth: true })
            //                 : scrollTo({ y: 4100, smooth: true })
            //               : window.innerWidth < 1300
            //               ? window.innerWidth < 600
            //                 ? scrollTo({ y: 5750, smooth: true })
            //                 : scrollTo({ y: 5600, smooth: true })
            //               : scrollTo({ y: 4800, smooth: true });
            //           }}
            //         >
            //           {buildType === "tutor"
            //             ? "Book Your App Now"
            //             : "Get Your Free App"}
            //         </button>
            //       )}
            //     </ScrollTo>
            //   )}
            //   {
            //     // <div
            //     //   className="landing__content--sub landing__content--sub--prompt landing__content--sub--prompt--2"
            //     //   style={{
            //     //     visibility: window.innerWidth < 768 ? "visible" : "hidden",
            //     //   }}
            //     // >
            //     //   #ClassplusForCoachings
            //     // </div>
            //   }
            // </div>
          }
        </div>

        <div className="landing__form">
        {/* {window.screen.width >700 ?  */}
        <React.Fragment>
            <div className="landing__form--header" id="form">
              <div className="landing__form--header--heading">
                {buildType === "tutor"
                  ? `Get your own mobile app and sell your courses`
                  : `Get your teaching app & Start teaching instantly!`}
              </div>
              <div className="landing__form--header--content green--text" />
            </div>

            <div style={{margin: '0 auto'}}>
              <Form source="landing" />
            </div>
          </React.Fragment>
          {/* :
          <div className="whatsappBtn btn btn--bookADemo form__button" style={{width:"100%",justifyContent:"center",margin:"auto",alignItems:"center",
          marginTop:"24px",borderRadius:"4px",boxShadow:"none"}}>
              <a  href="https://wa.me/918588809864?text=Hi+Classplus" 
                  target="_blank" className="whatsappAnchor">
                <img
                  alt=""
                  src={whatsappCTA}
                  
                />
                <span style={{ paddingLeft: "8px" }}>
                  Book a Free Demo
                </span>
              </a>
            </div>} */}
          {/* {
              <div
                className="landing__content--sub landing__content--sub--prompt landing__content--sub--prompt--2"
                style={{ color: "transparent" }}
              >
                #ClassplusForCoachings
              </div>
            } */}
        </div>
          
        
      </div>
    );
  }
}

export default Landing;
