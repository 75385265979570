import React from 'react'

import SVGattendence from '../images/SVGattendence.svg';
import SVGChat from '../images/SVGChat.svg';
import SVGClass from '../images/SVGClass.svg';
import SVGDatabase from '../images/SVGDatabase.svg';
import SVGFees from '../images/SVGFees.svg';
import SVGOnlineTest from '../images/SVGOnlineTest.svg';
import SVGPrivacy from '../images/SVGPrivacy.svg';
import SVGReports from '../images/SVGReports.svg';




export const FeaturesList = () => {
    return (
        
                
                <div className="features__content--list grid--3">
                    <div className="features__content--list--item">
                        <img src={'https://d21qmqg3g5bkb7.cloudfront.net/production/single/learn/d1f0167f-45c9-4f4b-aa71-b511552573a7.png'} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                            Content Security
                        </p>
                        <p className="features__content--list--item--content p--small ">
                        Your data is secured using SSL AES 256-bit encryption
                        </p>

                    </div>
                    <div className="features__content--list--item">
                        <img src={'https://d21qmqg3g5bkb7.cloudfront.net/production/single/learn/65029e43-174a-43b1-97ab-5a21e50b5b33.png'} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                        Dedicated Growth Manager
                        </p>
                        <p className="features__content--list--item--content p--small">
                        Along with app, you also get a dedicated growth manager who'll help you grow your business 10x                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={'https://d21qmqg3g5bkb7.cloudfront.net/production/single/learn/28330f5e-e582-4d39-a7c4-9f8abe73885c.png'} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                        Solve Student Doubts
                        </p>
                        <p className="features__content--list--item--content p--small">
                        With in-built chat feature you can clear the doubts of your students easily                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={'https://d21qmqg3g5bkb7.cloudfront.net/production/single/learn/37314076-232a-4685-bb6c-afb253e0dae3.png'} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                        Sell Courses                        </p>
                        <p className="features__content--list--item--content p--small">
                        List, sell and receive payment for your courses directly on your app                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={'https://d21qmqg3g5bkb7.cloudfront.net/production/single/learn/a5b56a42-a5d6-43b1-9939-228d88a5c58e.png'} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                        Live Classes                        </p>
                        <p className="features__content--list--item--content p--small">
                        Set up multiple live classes with automatic recordings within seconds                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={'https://d21qmqg3g5bkb7.cloudfront.net/production/single/learn/6911d8af-1089-4e00-9670-05f7dcd8a78c.png'} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                        Video Lectures                        </p>
                        <p className="features__content--list--item--content p--small">
                        Upload unlimited video lectures in an organized format                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={'https://d21qmqg3g5bkb7.cloudfront.net/production/single/learn/154e78e9-cc1e-4e0c-b9e4-b834cfc6b2e1.png'} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                        Online Tests                        </p>
                        <p className="features__content--list--item--content p--small">
                        Easily create, upload, and assign tests with auto-correction features to all your students                        </p>

                    </div>
                    <div className="features__content--list--item">
                        <img src={'https://d21qmqg3g5bkb7.cloudfront.net/production/single/learn/afd55b26-8d3f-46b0-a72a-bcd43622235c.png'} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                        Biggest Student Community                        </p>
                        <p className="features__content--list--item--content p--small">
                        Engage over 40k students with your own app at the same time                        </p>

                    </div>
                </div>
          
    )
}

export default FeaturesList