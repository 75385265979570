import React, { Component, Suspense, lazy } from "react";
import Navigation from "./components/Navigation";
import Home from "./components/Home";
import Footer from "./components/Footer";
import DetailedFeatures from "./components/DetailedFeatures";
import NewDetailedFeatures from "./components/NewDetailedFeature";
import TnC from "./components/TnC";
import Policy from "./components/Policy";
import ScrollToTop from "./ScrollToTop";
import "./App.scss";
import { setBuildType, setBaseUrl, buildType } from "./utils/constant";
import ThankYou from "./components/ThankYou";
import whatsappCTA from "./images/whatsappCTA.svg"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";


class App extends Component {
  // componentDidMount(){
  //     if(window.screen.width<700 &&
  //       window.location.href.includes("/") &&
  //         window.location.href.includes("/features")
  //         || window.location.href.includes("/policy") ||
  //         window.location.href.includes("/tnc")
  //         ){
  //         document.getElementById('footer-btn').style.display="flex"
  //     }
  //     else{
  //         document.getElementById('footer-btn').style.display="none"
  //     }
  // }


  componentWillMount() {
    setBuildType();
    setBaseUrl();
  }

  render() {
    return (
      <div className="App fade-in" style={{ position: "relative" }}>
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
            <Navigation />
            <ScrollToTop>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/features" component={DetailedFeatures} />
                <Route
                  exact
                  path="/new-features"
                  component={NewDetailedFeatures}
                />
                <Route exact path="/tnc" component={TnC} />
                <Route exact path="/policy" component={Policy} />
                {buildType === "general" && (
                  <Route
                    exact
                    path="/thankyou/:userType"
                    component={ThankYou}
                  />
                )}
                <Route render={() => <Redirect to="/" />} />
              </Switch>
              <Footer />
            </ScrollToTop>
          </BrowserRouter>
        </Suspense>
        <div className="whatsappCTAMobile_footer scroll-to-target" id="footer-btn" onClick={()=>{
          window.open('https://wa.me/918588025642&text=Hi+Classplus!+Help+me+grow+my+coaching+online')
        }}>
          <a className="whatsappCTAMobile">
            <img src={whatsappCTA} alt="" />
            {/* <span>Book a Free Demo</span> */}
          </a>
        </div>
       

        {
          //   <a
          //   href="tel:8588809776"
          //   target="_blank"
          //   className="callButton"
          //   onClick={() => {
          //     window.gtag("event", "button_click", {
          //       event_category: "click_to_call",
          //       event_action: "button_click",
          //       event_label: "click_call_button_header",
          //     });
          //   }}
          // >
          //   <img src={SVGCall} className="callButton__img" />
          // </a>
        }
      </div>
    );
  }
}

export default App;
