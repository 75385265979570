import React from "react";
//import {Link} from 'react-router-dom';
import ClassplusLogo from "../images/classplusLogo.svg";
import { ScrollTo } from "react-scroll-to";
import { buildType } from "../utils/constant";
import { withRouter } from "react-router-dom";
import SVGPhone from "../images/SVGPhone.svg";

export const Navigation = (props) => {
  let { pathname } = props.location;
  let showCTAButton = !RegExp(/thankyou\/*/i).test(pathname);
  let telNumber = '0-4954-25-4954'
  let logoHref = 'https://tutors.classplusapp.com'

  if (window.location.href.includes('new-features')){
      telNumber = '8047189001'
      logoHref  = 'https://tutors.classplusapp.com/new-page'
  }

  return (
    <div>
      <div className="blueStrip"></div>
      <div className="navigation">
        <div className="navigation__logo">
          <a href={`${logoHref}`}>
            <img
              src={ClassplusLogo}
              alt="Classplus"
              className="navigation__logo--img"
            />
          </a>
        </div>
        {showCTAButton && (
          <div className="navigation__book">
            {buildType != "tutor" ? (
              <ScrollTo>
                {({ scrollTo }) => (
                  <button
                    className="btn--bookADemo btn"
                    onClick={() => {
                      window.innerWidth > 600
                        ? window.innerWidth > 1400
                          ? window.innerWidth > 1600
                            ? scrollTo({ y: 4600, smooth: true })
                            : scrollTo({ y: 3900, smooth: true })
                          : scrollTo({ y: 4100, smooth: true })
                        : window.innerWidth < 1300
                        ? window.innerWidth < 600
                          ? scrollTo({ y: 4500, smooth: true })
                          : scrollTo({ y: 3600, smooth: true })
                        : scrollTo({ y: 3800, smooth: true });
                    }}
                  >
                    {" "}
                    {buildType == "tutor"
                      ? "Book Your App Now"
                      : "Get Your Free App"}
                  </button>
                )}
              </ScrollTo>
            ) : (
              <a
                href={`tel:${telNumber}`}
                target="_blank"
                onClick={() => {
                  // window.gtag("event", "button_click", {
                  //   event_category: "click_to_call",
                  //   event_action: "button_click",
                  //   event_label: "click_call_button_header",
                  // });
                }}
                style={{
                  background: "#009ae0",
                  color: "#fff",
                  padding: "1rem 1.6rem",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "1.6rem",
                }}
              >
                {" "}
                Sales{" "}
                <img
                  src={SVGPhone}
                  style={{
                    margin: "0 0.5rem",
                    height: "1.6rem",
                  }}
                />{" "}
                {telNumber}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Navigation);

// <Dropdown text="Contact Us">
//                 <Dropdown.Menu>
//                   <Dropdown.Item>
//                     <a
//                       href="tel:7948060430"
//                       target="_blank"
//                       onClick={() => {
//                         window.gtag("event", "button_click", {
//                           event_category: "click_to_call",
//                           event_action: "button_click",
//                           event_label: "click_call_button_header",
//                         });
//                       }}
//                     >
//                       {" "}
//                       <img
//                         src={SVGCall}
//                         style={{
//                           marginRight: "0.5rem",
//                           height: "1.8rem",
//                         }}
//                       />
//                       Sales Enquiry
//                     </a>
//                   </Dropdown.Item>

//                   <Dropdown.Item>
//                     <a
//                       href="tel:8588809776"
//                       target="_blank"
//                       onClick={() => {
//                         window.gtag("event", "button_click", {
//                           event_category: "click_to_call",
//                           event_action: "button_click",
//                           event_label: "click_call_button_header",
//                         });
//                       }}
//                     >
//                       {" "}
//                       <img
//                         src={SVGCall}
//                         style={{
//                           marginRight: "0.5rem",
//                           height: "1.8rem",
//                         }}
//                       />
//                       Customer Support
//                     </a>
//                   </Dropdown.Item>
//                 </Dropdown.Menu>
//               </Dropdown>
