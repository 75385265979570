import React from 'react'
import './Warriors.css'

function LockdownWarriors() {
    return (
        <div className="Warriors">
            <div className="Warriors__Top">How to earn ₹3,00,000 from YouTube?</div>
            <div className="Warriors__Body">    
                {/* <video
                autoPlay
                style={{outline: 'none'}} 
                width="100%"
                poster="https://d2sqt6igbl7quy.cloudfront.net/classplus-websites/common/static/img-Warriors.png"
                height={window.screen.width > 700 ? "539":"250"} 
                controls
                >
                    <source src="https://www.youtube.com/watch?v=aj_-ZamGplw" type="video/mp4" />
                    <source src="movie.ogg" type="video/ogg" />
                    Your browser does not support the video tag.
                </video> */}


                <iframe width="100%" height={window.screen.width > 700 ? "539":"250"} src="https://www.youtube.com/embed/aj_-ZamGplw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    )
}

export default LockdownWarriors
