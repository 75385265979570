import React from 'react'
import Carousel from './Carousel';
import FeaturesList from './FeaturesList';
//import {Link} from 'react-router-dom';


export const Features = () => {
    return(
        <div className="features">
            <div className="features__header">
                <div className="features__header--heading heading--mega">
                How does your app help you grow?
                                </div>
                <div className="features__header--content p--medium green--text">
                With your own app you get all the utilities needed to grow bigger and faster, on one single platform.
                </div>
            </div>
            <div className="features__content">
                {/* <Carousel /> */}
                <FeaturesList />
            </div>
            {
            //     <div className="features__link">
            //     <Link to="/features">Learn more about Classplus features</Link>
            // </div>
        }
        </div>
    )
}

export default Features