import React from 'react'
import {cityCount, tutorCount, userCount, buildType} from '../utils/constant'


export const Reach = () => {

    return (
        <div className="Reach">
                                  

            <div className="Reach__content grid--5" style={{    color: 'rgba(255, 255, 255, 0.9)',
    fontWeight: '700',
    fontSize: '2.5rem',  margin: '0 3rem',
    textAlign: 'center'}}>
            {'1,00,000+ Teachers are teaching online with their own app'}
                {/* <div className="Reach__content--item">
                    <div className="Reach__content--item--heading heading--mega">
                        {cityCount}+  Cities
                    </div>
                    {
                        //     <div className="Reach__content--item--content">
                        //     Cities
                        // </div>
                    }
                </div> */}
                {/* <div className="Reach__content--item">
                    <div className="Reach__content--item--heading heading--mega tutorsMobile">
                        {'1,00,000+ Teachers are teaching online with their own app'}
                    </div>
                    {
                        //     <div className="Reach__content--item--content" style={{"text-align":"center","margin-right":"2.4rem"}}>
                        //     Tutors
                        // </div>
                    }
                </div> */}
                {/* <div className="Reach__content--item">
                    <div className="Reach__content--item--heading heading--mega">
                        {userCount}+  Students
                    </div>
                    {
                        //     <div className="Reach__content--item--content">
                        //   Users
                        // </div>
                    }
                </div> */}
            </div>
        </div>
    )
}

export default Reach